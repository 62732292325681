import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Component } from "./Footer.style";
import { ThemeProvider } from "styled-components";
import { theme } from "./../../theme";

const Footer = (props) => {
  const { color, children, className, tag, ...attributes } = props;

  const classes = classNames("page-footer", color && color, className);

  return (
    <ThemeProvider theme={theme}>
      <Component as={tag} data-test="footer" {...attributes} className={classes}>
        {children}
      </Component>
    </ThemeProvider>
  );
};

Footer.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  color: PropTypes.string,
  tag: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
};

Footer.defaultProps = {
  tag: "footer",
};

export default Footer;
export { Footer as CDBFooter };
